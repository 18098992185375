import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "@/views/Home";

Vue.use(VueRouter);
const routes = [{
        path: "/",
        name: "Main",
        // component:Home
        redirect: "home", //页面打开默认展示home路由
        component: () =>
            import ("@/views/Main.vue"),
        children: [{
                path: "/home",
                name: "home",
                component: () =>
                    import ("@/views/home"),
            },
            {
                path: "/user",
                name: "user",
                component: () =>
                    import ("@/views/user"),
            },
            {
                path: "/search",
                name: "search",
                component: () =>
                    import ("@/views/searchuser"),
            },
            {
                path: "/intelaction",
                name: "intelaction",
                component: () =>
                    import ("@/views/intelaction"),
            },

            // {
            //     path: "/props",
            //     name: "props",
            //     component: () => import("@/views/props")
            // },

            {
                path: "/interview",
                name: "interview",
                component: () =>
                    import ("@/views/interview"),
            },

            {
                path: "/firm",
                name: "firm",
                component: () =>
                    import ("@/views/firm"),
            },
            {
                path: "/setuser",
                name: "setuser",
                component: () =>
                    import ("@/views/setuser"),
            },
            {
                path: "/my",
                name: "my",
                component: () =>
                    import ("@/views/my"),
            },
        ],
    },
    {
        path: "/agreement",
        name: "agreement",
        component: () =>
            import ("@/views/agreement"),
    },
];
const router = new VueRouter({
    mode: "hash",
    routes,
    // scrollBehavior: () => {
    //     history.pushState(null, null, document.URL)
    // }
});

export default router;