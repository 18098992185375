<template>
  <div id="app" style="height: 100%">
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "App",
  components: {},
  data() {
    return {
      // url: 'https://pc.dixinbao.cn/#/home?phone='
    };
  },
  created() {
    // 获取完整的 URL
    // const url = this.url;
    const url = window.location.href;

    // 查找问号的位置
    const questionMarkIndex = url.indexOf('?');

    if (questionMarkIndex !== -1) {
      // 截取问号后面的部分
      const queryString = url.substring(questionMarkIndex + 1);

      // 将查询字符串分割成参数数组
      const paramsArray = queryString.split('&');

      // 创建一个对象来存储参数
      const params = {};

      // 遍历参数数组，解析每个参数的键和值
      paramsArray.forEach(param => {
        const [key, value] = param.split('=');
        params[key] = value;
      });

      // 输出参数对象
      localStorage.setItem('phone',params.phone);
      console.log(params.phone,'接收参数')
      // this.login()
    }
    // this.getInfo();
  },
  mounted() {},
  methods:{
    login(){
      this.$http({
        method: "POST",
        url: "login/rhpcmobilelogin",
        data: {
          mobile: localStorage.getItem('phone')
        },
      }).then((res) => {
        // sessionStorage.setItem("token",res.data.data.userinfo.token);
        // localStorage.setItem('userinfo',res.data.userinfo);
        console.log(res.data.data.userinfo.token,'请求终极结果')
      });
    }
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* 后期添加的滚动条 */
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
</style>
